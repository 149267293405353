{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "dias",
      "hours": "hr",
      "minutes": "min"
    },
    "Body_text": "Para el  <span>25 aniversario de SNIPES,</span> hemos colaborado con dos de nuestras marcas favoritas -Pegador y Karl Kani- en una colección cápsula de primera calidad.\nEl resultado es una oferta cumbre y una flexión no tan sutil para celebrar este hito de la mejor manera.\nMarca en tu calendario el 4 de abril para ser de los primeros en hacerte con estas piezas exclusivas de alta calidad.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "gris, beige",
        "Link": "https://www.snipes.es/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "blanco, gris, naranja",
        "Link": "https://www.snipes.es/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id": "00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "Azul desteñido",
        "Link": "https://www.snipes.es/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id": "00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.es/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id": "00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "próximamente",
      "Active": "comprar ahora"
    }
  },
  "Editorial": {
    "Editorial_body_text": "Después de veinticinco años en SNIPES, podemos mirar atrás con orgullo en un viaje increíble.\nInnumerables productos exclusivos, colaboraciones sorprendentes y eventos inigualables han consolidado un legado impactante.\nPero aún más importante es la creciente familia SNIPES que hemos construido a lo largo del camino y los grandes momentos que hemos vivido juntos.\nAunque esto es motivo de celebración, no es señal de que vayamos a bajar el ritmo. ¡ Seguiremos construyendo e impulsando aún más la cultura de la calle!"
  },
  "UGC": {
    "UGC_body_text": "Las inspiradoras comunidades de todas las disciplinas de la cultura streetwear siempre han estado en el corazón de lo que hace SNIPES.\nNos complace haber ayudado a tender puentes entre ellas a lo largo de los años, reuniendo a creativos apasionados y ofreciéndoles plataformas de intercambio y expresión.\nAl fin y al cabo: there's no culture without community!"
  },
  "Raffle": {
    "Raffle_body_text": "Queremos celebrar nuestro 25 aniversario contigo y estamos sorteando tres premios increíbles para los miembros de nuestro CLIQUE SNIPES.\n\nCon un poco de suerte, ganarás el grande: ¡ Un viaje todo pagado a Miami!\n\nPero también un paquete de entrada VIP para el festival Openair Frauenfeld y veinticinco pares de zapatillas para ganar.\n\nSo don't miss out!\n\nPara participar, todo lo que tienes que hacer es unirte a nuestro SNIPES CLIQUE y rellenar el formulario del sorteo a través del botón de abajo.",
    "Link":"https://www.snipes.es/contest-show"
  }
}