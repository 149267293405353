import React from "react";
import { useTranslation } from "react-i18next";
import LadyInStyle from "../../assets/img/UGC-image-1x.png";
import Gif from "../../assets/img/UGC-gif.gif";
import FloatingConcrete from "../../assets/img/Floating-concrete-1x.png";
import Clique from "../../assets/img/UGC-clique.png";
import {
  ColumnLeft,
  ColumnRight,
  Container,
  Headline,
  StoresContainer,
  StyledAppleStore,
  StyledGoogleStore,
  StyledSection,
  SubHeadline,
  Text,
  WhiteSubHeadline
} from "./UGC.styles";
import useConfigStore from "../../stores/useConfigStore";
import Composition from "../../assets/img/ugc.png";

const urls = {
  apple: "https://app.adjust.com/z01vwqd?campaign=snipes_25birthday&adgroup=landingpage_sall",
  android: "https://app.adjust.com/z01vwqd?campaign=snipes_25birthday&adgroup=landingpage_sall"
};

export default function UGC() {
  const { t } = useTranslation();
  const isClique = useConfigStore((state) => state.isClique);
  const isCliqueNotAvailable = useConfigStore((state) => state.isCliqueNotAvailable);

  return !isCliqueNotAvailable ? (
    <StyledSection>
      <Container>
        <ColumnLeft>
          <img src={Composition} width="85%" alt="snipes" />
        </ColumnLeft>
        <ColumnRight>
          <SubHeadline>
            TIME TO
            <br />
            CELEBRATE YOU
          </SubHeadline>
          <Text>{t("UGC.UGC_body_text")}</Text>
          <StoresContainer>
            {!isClique && (
              <>
                <StyledAppleStore onClick={() => window.open(urls.apple, "_blank")} />
                <StyledGoogleStore
                  onClick={() => window.open(urls.android, "_blank")}
                  style={{ marginLeft: "1rem" }}
                />
              </>
            )}
          </StoresContainer>
          <img src={FloatingConcrete} alt="floating-concrete" />
        </ColumnRight>
      </Container>
    </StyledSection>
  ) : null;
}
