import styled from "styled-components";
import { vp } from "../../common/layout";
import Background from "../../assets/img/UGC-bg.jpg";
import GoogleStore from "../../assets/svg/GoogleStore";
import AppleStore from "../../assets/svg/AppleStore";

export const StyledSection = styled.section`
  background-color: #2e2a2a;
  background-image: url(${Background});
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  ${vp.small(`
    height: auto;
    min-height: 150vh;
`)};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  color: white;

  ${vp.small(`
  flex-direction: column;
  margin: 1rem 0;

  `)};
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ColumnLeft = styled(Column)`
  display: grid;
  margin-top: 2rem;

  > :nth-child(1) {
   place-self: center;

   ${vp.small(`
    width: 100%;
    
    `)}
    @media (min-width: 1025px) and (max-width: 1280px) and (orientation: portrait) {
      width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
  }
`;

export const ColumnRight = styled(Column)`
  justify-content: center;
  margin: 0 1rem;

  > img {
    position: absolute;
  }
  
  ${vp.small(`
  justify-content: flex-start;
`)};

  > :nth-child(4) {
    top: 0;
    right: 0;

    ${vp.small(`
    display: none;
`)};
    ${vp.medium(`
    width: 300px;`)};
  }
`;

export const Headline = styled.h4`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13rem;
  font-weight: 500;
  margin: 0;

  ${vp.small(`
    font-size: 8.5rem;
    margin-left: 40px;
    `)}

    ${vp.medium(`
    font-size: 9.5rem;
    `)}
`;

export const SubHeadline = styled.h4`
  font-size: 3rem;
  max-width: 15ch;
  margin: 2rem 0;
  ${vp.small(`
    margin-top: 5rem;
    font-size: 48px;
  `)};
`;

export const WhiteSubHeadline = styled.h2`
  position: absolute;
  font-size: 3.8rem;
  font-weight: 200;
  color: white;
  max-width: 8ch;
  letter-spacing: -1px;
`;

export const Text = styled.p`
  font-size: 16px;
  max-width: 35ch;
  margin: 0;
  color: white;
  white-space: pre-line;

  ${vp.small(`
    max-width: 90%;
    font-size: 0.9rem;
    `)}
`;

export const StoresContainer = styled.div`
  display: block;
  margin-top: 2rem;
  height: min-content;
`;
export const StyledAppleStore = styled(AppleStore)`
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const StyledGoogleStore = styled(GoogleStore)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
