{
  "3D_Stage": {
    "Countdown": {
      "days": "jours",
      "hours": "hrs",
      "minutes": "mins"
    },
    "Body_text": "Pour le <span>25e anniversaire de SNIPES,</span> nous avons collaboré avec deux de nos marques préférées - Pegador et Karl Kani - sur une collection capsule premium.\nLe résultat est une collection exceptionnelle et un big flex pour célébrer ce chapitre.\nMarque ton agenda pour le 4 avril afin d'être parmi les premiers à mettre la main sur ces pièces exclusives et de haute qualité.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "gris, beige",
        "Link": "https://www.snipes.be/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "blanc, gris, orange",
        "Link": "https://www.snipes.be/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id":"00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "bleu blanchi",
        "Link": "https://www.snipes.be/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id":"00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.be/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id":"00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "bientôt disponible",
      "Active": "acheter"
    }
  },
  "Raffle": {
    "Raffle_body_text": "Nous voulons célébrer notre 25e anniversaire avec toi, c'est pourquoi nous organisons un tirage au sort de trois prix extraordinaires pour les membres de notre SNIPES CLIQUE\n\nAvec un peu de chance, tu gagneras le gros lot : un voyage à Miami !\n\nMais il y a aussi un lot de billets VIP pour le festival Openair Frauenfeld et un sneaker flat comprenant vingt-cinq paires à gagner.\n\nAlors ne laisse pas passer ta chance !\n\nPour participer, il te suffit de rejoindre notre SNIPES CLIQUE et de remplir le formulaire de tirage au sort via le bouton ci-dessous.",
    "Link":"https://www.snipes.be/contest-show"
  }
}