import styled, { css } from 'styled-components'

export const theme = {
  breakpoints: {
    sm: '460px',
    md: '769px',
    lg: '960px',
    xl: '1440px',
  },
}

export const breakPoints = {
  small: { min: 280, max: 767 },
  medium: { min: 768, max: 1365 },
  large: { min: 1366, max: 1999 },
  extraLarge: { min: 2000, max: 100000 },
  mobile: { portrait: 768, landscape: 1024 },
}

export const isLandscape = () => window.matchMedia('only screen and (orientation:landscape)').matches
export const isPortrait = () => window.matchMedia('only screen and (orientation:portrait)').matches
export const isMaxScreenWidth1080 = () => window.matchMedia('only screen and (max-width: 1080px) and (orientation:portrait)').matches

export const isMobile = () => (isLandscape()
  ? window.matchMedia(`only screen and (max-height: ${breakPoints.mobile.portrait}px)`).matches && window.matchMedia(`only screen and (max-width: ${breakPoints.mobile.landscape}px)`).matches
  : window.matchMedia(`only screen and (max-width: ${breakPoints.mobile.portrait}px)`).matches)

export const isSmallScreenWidth = () => window.matchMedia('only screen and (max-width: 768px)').matches
export const hasSmallScreenWidth = isSmallScreenWidth()

export const mobileCss = (style) => (isMobile() ? css`${style}` : '')

export const mediaMin = (breakpoint, style) => css`
  @media only screen and (min-width: ${breakPoints[breakpoint].min}px) {
    ${style}
  }
`

export const mediaMax = (breakpoint, style) => css`
  @media only screen and (max-width: ${breakPoints[breakpoint].max}px) {
    ${style}
  }
`

export const mediaMaxPx = (pixel, style) => css`
  @media only screen and (max-width: ${pixel}px) {
    ${style}
  }
`

export const mediaMinMax = (breakpointMin, breakpointMax, style) => css`
  ${mediaMin(breakpointMin, mediaMax(breakpointMax, style))}
`

const extraSmall = (style) => css`
  ${mediaMinMax('extraSmall', 'extraSmall', style)}
`

const small = (style) => css`
  ${mediaMinMax('small', 'small', style)}
`

const medium = (style) => css`
  ${mediaMinMax('medium', 'medium', style)}
`

const large = (style) => css`
  ${mediaMinMax('large', 'large', style)}
`

const extraLarge = (style) => css`
  ${mediaMinMax('extraLarge', 'extraLarge', style)} 
`
export const vp = {
  extraSmall,
  small,
  medium,
  large,
  extraLarge,
  isMobile,
  mobileCss,
}

export const StickyContainer = styled.div`
  height: 1000vh;
  width: 100%;
`;
export const TrackerContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const StyledButton = styled.button`
  background-color: #ea640c;
  color: white;
  font-size: 1.1rem;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8.25px;
  margin-top: 1rem;
  text-transform: uppercase;
  cursor: pointer;

${vp.small(`
  width: 90%;
  `)}
`;

export const Overlay = styled.div`
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(5, 5, 5, 1) 100%);
`;
