import React, { useState } from "react";

export default function AppMenu() {
  const locales = [
    "de-DE",
    "de-AT",
    "nl-NL",
    "en-EN",
    "fr-FR",
    "es-ES",
    "it-IT",
    "pl-PL",
    "hr-HR",
    "en-PT",
    "nl-BE",
    "fr-BE",
    "en-HR",
    "de-CH",
    "fr-CH"
  ];

  const [selectedLocale, setSelectedLocale] = useState(locales[0]);
  const [debugCountdownOver, setDebugCountdownOver] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [noRaffle, setNoRaffle] = useState(false);

  let query = `?locale=${selectedLocale}`;
  if (debugCountdownOver) query += "&debugCountdownOver";
  if (isSoldOut) query += "&isSoldOut";
  if (noRaffle) query += "&noRaffle";

  return (
    <>
      <h1>Widget Configuration</h1>
      <ul>
        <li>
          Locale:
          <select value={selectedLocale} onChange={(e) => setSelectedLocale(e.target.value)}>
            {locales.map((locale) => (
              <option key={locale}>{locale}</option>
            ))}
          </select>
        </li>
        <li>
          debugCountdownOver:
          <input
            type="checkbox"
            checked={debugCountdownOver}
            onChange={(z) => {
              setDebugCountdownOver(z.target.checked);
            }}
          />
        </li>
        <li>
          isSoldOut:
          <input
            type="checkbox"
            checked={isSoldOut}
            onChange={(z) => {
              setIsSoldOut(z.target.checked);
            }}
          />
        </li>
        <li>
          noRaffle:
          <input
            type="checkbox"
            checked={noRaffle}
            onChange={(z) => {
              setNoRaffle(z.target.checked);
            }}
          />
        </li>
      </ul>
      <input
        type="submit"
        value="Start"
        onClick={() => {
          window.location.href = `sampleApp.html${query}`;
        }}
      />
      <br />
      <br />
      <code>{query}</code>
    </>
  );
}
