{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "tage",
      "hours": "std",
      "minutes": "min"
    },
    "Body_text": "Zum <span>25-jährigen Jubiläum von SNIPES</span> haben wir mit zwei unserer besten Brands - Pegador und Karl Kani - an einer Premium Capsule Collection gearbeitet.\nDas Ergebnis ist ein einzigartiger Drop, der diesen Meilenstein, mit einem nicht gerade subtilen Flex, gebührend feiert.\nMarkiert Euch den 4. April im Kalender, um zu den ersten zu gehören, die diese exklusiven, hochwertigen Teile in die Hände bekommen.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "grey, beige",
        "Link": "https://www.snipes.com/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"

      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "white, grey, orange",
        "Link": "https://www.snipes.com/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id": "00013802211121"

      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "bleached blue",
        "Link": "https://www.snipes.com/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id": "00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.com/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id": "00013802218098"

      }
    ]
  },
  "Campaign_movie": {},
  "Editorial": {
    "Editorial_body_text": "Nach fünfundzwanzig Jahren SNIPES können wir stolz auf eine erstaunliche Reise zurückblicken.\nUnzählige exklusive Produkte, überragende Kooperationen und einzigartige Events haben eine beeindruckende Legacy etabliert.\nAber noch wichtiger: Die stetig wachsende SNIPES-Family und die großartige Zeit, die wir alle gemeinsam erlebt haben.\nDas ist natürlich ein Grund zum Feiern, aber kein Anlass für uns langsamer zu werden. Wir bleiben dran und pushen Street Culture immer weiter!"
  },
  "UGC": {
    "UGC_body_text": "Die inspirierenden Communities aus allen Bereichen der Street Culture standen schon immer im Mittelpunkt von allem, was wir bei SNIPES tun.\nWir freuen uns, dass wir im Laufe der Jahre dazu beitragen konnten, Brücken zu bauen - leidenschaftliche Kreative zusammenzubringen und ihnen Plattformen für Austausch und Ausdruck zu bieten.\nSchließlich gilt: No culture without community!"
  },
  "Raffle": {
    "Raffle_body_text": "Wir wollen unser 25-jähriges Jubiläum mit Euch feiern und verlosen unter den Mitgliedern unserer SNIPES CLIQUE drei großartige Preise.\n\nMit etwas Glück gewinnst du den Hauptpreis: eine Reise nach Miami!\n\nZu gewinnen gibt es aber auch ein VIP-Ticket-Paket für das Openair Frauenfeld und eine Sneaker-Flat mit fünfundzwanzig Paaren.\n\nAlso nicht verpassen!\n\nUm mitzumachen, musst du nur unserer SNIPES CLIQUE beitreten und über den untenstehenden Button das Gewinnspielformular ausfüllen.",
    "Link":"https://www.snipes.com/contest-show"
  }
}
