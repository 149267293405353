import styled from "styled-components";
import { vp } from "../../common/layout";
import NoiseTexture from "../../assets/img/Grey-bottom-gradient.jpg";

export const NoiseOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-image: url(${NoiseTexture});
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
`;

export const BackgroundContainer = styled.div`
  position: fixed;
  z-index: -2;
  background-color: #050505;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  img {
    padding: 0 0 100px 650px;
    ${vp.small(`
      scale: 0.65;
      margin: 0 auto;
      padding-left: 0;
  `)};

  ${vp.large(`
      scale: 1.25;
      padding-left: 450px; 
  `)};
  
  ${vp.extraLarge(`
      align-self: center;
      scale: 1.5;
      padding-left: 390px;
    `)}
  }
  
`;
