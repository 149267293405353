import { createRoot } from 'react-dom/client'
import { initI18n } from './translations/i18n';
import useConfigStore from "./stores/useConfigStore";
import Page from './components/Page/Page';
import AppMenu from "./components/AppMenu/AppMenu";

if (!window.lp25thConfig) {
  const domNode = document.createElement("div");
  document.body.append(domNode)
  const root = createRoot(domNode);
  root.render(<AppMenu />);
} else {
  useConfigStore.getState().initFromWindow()
  initI18n(useConfigStore.getState().locale)
  const container = document.getElementById(useConfigStore.getState().widget);
  const root = createRoot(container);
  root.render(<Page />);
}
