import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLottie } from "lottie-react";
import { useTranslation } from "react-i18next";
import { Overlay, isMobile } from "../../common/layout";
import DesktopMap from "../../assets/map/Desk_01.json";
import MobileMap from "../../assets/map/Mobile_01.json";
import {
  AnimatedHeadline,
  BrushTexture,
  CountHeadline,
  HeadingsContainer,
  MapContainer,
  OrangeBar,
  OrangeHeadline,
  PaperTexture,
  StyledSection,
  SubHeadline,
  TitleHeadline
} from "./Expansion.styles";


gsap.registerPlugin(ScrollTrigger);

export default function Expansion() {
  const sectionRef = useRef<HTMLHeadingElement>(null);
  const headlineRef = useRef<HTMLHeadingElement>(null);
  const { t, ready } = useTranslation();

  const [contentShown, setContentShown] = useState(false);

  const countUpRef = useRef<HTMLDivElement>(null);


  // marque
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, yoyo: true });
    tl.fromTo(
      headlineRef.current,
      {
        x: -500,
        duration: 20
      },
      {
        x: 400,
        duration: 20
      }
    );
    return () => {
      tl.kill();
    };
  }, []);


  const lottie = useLottie({
    autoplay: false,
    animationData: isMobile() ? MobileMap : DesktopMap,
    style: { height: "100%", opacity: 1 },
    renderer : "canvas",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  });

  // map control and counter based on scroll
  useEffect(() => {
    const totalFrames = isMobile() ? MobileMap.op : DesktopMap.op;
    const tl = gsap.timeline();
    tl.to(
      {},
      {
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 0%",
          end: "bottom 0%",
          // markers: true,
          pin: true,
          onUpdate: async (scrollTrigger) => {
            const { progress } = scrollTrigger;
            const frame = totalFrames * progress;
            lottie.goToAndStop(frame, true);
            countUpRef.current!.innerHTML = `${Math.round(1998 + progress * (2023 - 1998))}`;
          },

          onToggle: (scrollTrigger) => {
            if (scrollTrigger.isActive) {
              setContentShown(true);
              // countUpHook.start();
            } else {
              setContentShown(false);
              // countUpHook.reset();
            }
          }
        }
      }
    );

    return () => {
      tl.kill();
    };
  }, []);

  // handle content should be shown or not
  useEffect(() => {
    const tl = gsap.timeline();
    if (contentShown) {
      tl.fromTo(
        ".fadeIn",
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: "power2.inOut", delay: 0.1 }
      );
    } else {
      tl.to(".fadeIn", { opacity: 0, duration: 1, ease: "power2.inOut" });
    }
    return () => {
      tl.kill();
    };
  }, [contentShown]);

  return (
    <StyledSection ref={sectionRef}>
      <Overlay />
      <OrangeBar>
      <AnimatedHeadline ref={headlineRef}>
          LET’S CELEBRATE! WE’RE TURNING 25. THANK YOU / LET’S CELEBRATE! WE’RE TURNING 25. THANK
          YOU / LET’S CELEBRATE! WE’RE TURNING 25. THANK YOU / LET’S CELEBRATE! WE’RE TURNING 25.
          THANK YOU / LET’S CELEBRATE! WE’RE TURNING 25. THANK YOU /
        </AnimatedHeadline>
      </OrangeBar>
      <PaperTexture />
      <BrushTexture />
      <HeadingsContainer>
        <TitleHeadline className="fadeIn">{ready && t("Expansion_Scrolly.Text_1")}</TitleHeadline>
        <CountHeadline ref={countUpRef}>1998</CountHeadline>
        <SubHeadline className="fadeIn">{ready && t("Expansion_Scrolly.Text_2")}</SubHeadline>
        <OrangeHeadline className="fadeIn">{ready && t("Expansion_Scrolly.Text_3")}</OrangeHeadline>
      </HeadingsContainer>
      <MapContainer>{lottie.View}</MapContainer>
    </StyledSection>
  );
}
