import React from "react";
import { useTranslation } from "react-i18next";
import PaperTextureImg from "../../assets/img/Paper-texture-2x.png";
import {
  Container,
  EditorialOverlay,
  Headline,
  StyledSection,
  Text,
  CompositionContainer,
  TextsContainer
} from "./EditorialContent.styles";
import Composition_desktop from "../../assets/img/Editorial_desktop.png";
import Composition_mobile from "../../assets/img/Editorial_mobile.png";
import { isMobile } from "../../common/layout";

export default function EditorialContent() {
  const { t } = useTranslation();
  return (
    <StyledSection>
      <EditorialOverlay />
      <img className="paper" src={PaperTextureImg} alt="paper-texture" />
      <Container>
        <TextsContainer>
          <Headline>{t("Editorial.Headline")}</Headline>
          <Text>{t("Editorial.Editorial_body_text")}</Text>
        </TextsContainer>
        <CompositionContainer>
          <img
            src={isMobile() ? Composition_mobile : Composition_desktop}
            style={{ margin: "3rem" }}
            width="60%"
            height="60%"
            alt="snipes-editorial"
          />
        </CompositionContainer>
      </Container>
    </StyledSection>
  );
}
