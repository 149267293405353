import styled from "styled-components";
import { vp } from "../../common/layout";

export const CountDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  position: relative;
`;

export const CounterContainer = styled.div`
  position: absolute;
  width: 300px;
  height: 100%;

  ${vp.small(`
    scale: 0.7;
    top: 20px;
  `)};
`;

export const Counter = styled.div`
  display: grid;
  grid-template-rows: 90px 80px;
  grid-template-columns: repeat(3,fit-content(100%));
  height: 100%;
  text-align: center;
  > :nth-child(6) {
    margin-right: 10px;
  }
  > :nth-child(3) {
    justify-self: flex-end;
  }
`;

export const Days = styled.div`
  color: white;
  font-size: 100px;
`;

export const Hours = styled.div`
  color: #ea640c;
  font-size: 100px;
  span {
    color: white;
  }
`;

export const Minutes = styled.div`
  color: #ea640c;
  font-size: 100px;
`;

export const Label = styled.div`
  color: white;
  font-size: 20px;
  grid-row: second-line/ 2;
  justify-self: flex-end;
  margin-right: 25px;
`;
