import styled from "styled-components";
import { vp } from "../../common/layout";
import OrangeCircle from "../../assets/img/Orange-circle.png";

export const Container = styled.div`
  width: 100%;
  display: none;
  grid-template-columns: repeat(2, 1fr);
  color: white;
  gap: 5rem;
  background-color: #050505;
  z-index: 1;

  ${vp.small(`
    display: flex;
    flex-direction: column;
    gap: 0;
  `)};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 3rem 1rem;
  img {
    margin: 0 auto;
  }
`;

export const Headline = styled.h1`
  font-size: 5rem;
  max-width: 15ch;
  z-index: 2;

  ::after {
    content: url(${OrangeCircle});
    position: absolute;
    z-index: -1;
    transform: translate(-87px, -40px);
  }

  ${vp.small(`
    margin-top: 7rem;
    font-size: 40px;
  `)};
`;

export const Text = styled.p`
  font-size: 22px;
  max-width: 70ch;
  margin: 0;
  span {
    color: #ea640c;
    margin: 0 0.2rem;
  }

  ${vp.small(`
 font-size: 14px;
`)};
`;
