import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useUiStore from "../../stores/useUiStore";
import dateChecker from "../../utils/dateChecker";
import {
  CountDownContainer,
  Counter,
  CounterContainer,
  Days,
  Hours,
  Minutes,
  Label
} from "./CountDown.styles";

export default function CountDown() {
  const { isTimeUp, setIsTimeUp } = useUiStore();
  const daysRef = React.useRef<HTMLDivElement>(null);
  const hoursRef = React.useRef<HTMLDivElement>(null);
  const minutesRef = React.useRef<HTMLDivElement>(null);
  const [date, setDate] = React.useState({
    days: "00",
    hours: "00",
    minutes: "00"
  });
  const { t, ready } = useTranslation();

  useEffect(() => {
    if (isTimeUp) return;
    const now = new Date().getTime();
    const countDownDate = new Date("April 4, 2023 00:00:00").getTime();
    if (countDownDate <= now) {
      // eslint-disable-next-line consistent-return
      setDate({ days: "00", hours: "00", minutes: "00" });
      setIsTimeUp(true);
    }
    const checkDate = setInterval(() => {
      const realNow = new Date().getTime();
      setDate(dateChecker(realNow, countDownDate));
    }, 3000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(checkDate);
  }, [isTimeUp, date.minutes, date.hours, date.days, setIsTimeUp]);

  return (
    <CountDownContainer>
      <CounterContainer>
        <Counter>
          <Days ref={daysRef}>
            {date.days}
            <span>:</span>
          </Days>
          <Hours ref={hoursRef}>
            {date.hours}
            <span>:</span>
          </Hours>
          <Minutes ref={minutesRef}>{date.minutes}</Minutes>
          <Label>{ready && t("3D_Stage.Countdown.days")}</Label>
          <Label>{ready && t("3D_Stage.Countdown.hours")}</Label>
          <Label>{ready && t("3D_Stage.Countdown.minutes")}</Label>
        </Counter>
      </CounterContainer>
    </CountDownContainer>
  );
}
