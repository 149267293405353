import styled from "styled-components";
import { vp } from "../../common/layout";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #050505;
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  position: fixed;
  inset: 0;
  touch-action: none;
  transition: opacity 1s ease-in-out;

  ${vp.small(`
    @media (orientation: landscape){
      display: flex;
      opacity: 1;
  }
  `)};
${vp.medium(`
  @media (orientation: landscape){
    display: flex;
    opacity: 1;
}`)}

  /* @media (orientation: landscape) and (min-width: 228px) and (max-width: 900px) {
    display: flex;
  } */
`;
export const Text = styled.p`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #ea640c;
`;
