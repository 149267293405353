import styled from "styled-components";
import MuxPlayer from "@mux/mux-video-react";
import SnipesLogo from "../../assets/svg/SnipesLogo";
import { vp } from "../../common/layout";
import HeaderImage from "../../assets/img/Header.jpg";

export const StyledHeader = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url(${HeaderImage});
  background-size: cover;
  background-position: center;
  /* background-color: #050505; */
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 530px;
  height: 360px;

  ${vp.small(`
    max-width: 80%;
    height: 300px;
  `)};
`;

export const StyledLogo = styled(SnipesLogo)`
  z-index: 2;
`;

export const StyledMuxPlayer = styled(MuxPlayer)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;
