/* eslint-disable no-param-reassign */
import useConfigStore from "../stores/useConfigStore";
import useUiStore from "../stores/useUiStore";

export default function dateChecker(now, countDownDate) {
  let distance = countDownDate - now;
  if (useConfigStore.getState().debugCountdownOver) {
    distance = 0
    useUiStore.getState().setIsTimeUp(true)
    return {
      days: "00",
      hours: "00",
      minutes: "00"
    }
  }

  // Calculate the days, hours, minutes and seconds remaining
  const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, "0");
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0");
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0");

  return {
    days,
    hours,
    minutes
  }
}
