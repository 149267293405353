import { create } from 'zustand';

type ProductIndex = number;
type Product = {
  index: ProductIndex;
};

type State = {
  isTimeUp: boolean;
  setIsTimeUp: (newState: boolean) => void;

  archVisible: boolean;
  setArchVisible: (newState: boolean) => void;

  products: Product[];
  selectedProductIndex: ProductIndex;
  setSelectedProductIndex: (productIndex: ProductIndex) => void;
}

const useUiStore = create<State>((set) => ({
  isTimeUp: false,

  setIsTimeUp: (newState: boolean) => {
    set({ isTimeUp: newState })
  },

  archVisible: false,

  setArchVisible: (newState: boolean) => {
    set({ archVisible: newState })
  },

  products: [
    {
      index: 0

    },
    {
      index: 1

    },
    {
      index: 2

    },
    {
      index: 3

    }
  ],
  selectedProductIndex: 0,

  setSelectedProductIndex: (productIndex: ProductIndex) => {
    set({ selectedProductIndex: productIndex });
  }

}));

export default useUiStore;
