import { create } from 'zustand';

type State = {
  locale: string;
  widget: string;
  debugCountdownOver: boolean;
  isSoldOut: boolean;
  noRaffle: boolean;
  initFromWindow: () => void;
  isClique: boolean;
  isCliqueNotAvailable: boolean;
}

const useConfigStore = create<State>((set, get) => ({
  locale: "",
  widget: "",
  debugCountdownOver: false,
  isSoldOut: false,
  noRaffle: false,
  isClique: false,
  isCliqueNotAvailable: false,

  initFromWindow: () => {
    const windowConfig = (window as any).lp25thConfig;
    const weAreInTheApp = !!(window as any).SnipesApp

    if (windowConfig.locale?.includes("HR") || windowConfig.locale?.includes("CH")) {
      set({ isCliqueNotAvailable: true })

      console.log('Clique is not available for this locale');
    }
    const config = {
      locale: windowConfig.locale || "de-DE",
      widget: windowConfig.widget,
      debugCountdownOver: !!windowConfig.debugCountdownOver,
      isSoldOut: !!windowConfig.isSoldOut,
      noRaffle: !!windowConfig.noRaffle,
    }
    set(config)
    set({ isClique: weAreInTheApp })
    console.log("Page rendering with config:", get())
    console.log("We are in the Clique app:", weAreInTheApp)
  }
}));

export default useConfigStore;
