import styled from "styled-components";

export const StyledSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #050505;
  overflow-x: hidden;
`;

export const Container = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
`;
