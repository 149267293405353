import React from "react";
import { LogoContainer, StyledHeader, StyledLogo, StyledMuxPlayer } from "./Header.styles";
import useUiStore from "../../stores/useUiStore";

export default function Header() {
  const { isTimeUp } = useUiStore();
  return (
    <StyledHeader>
      {isTimeUp && (
        <StyledMuxPlayer
          playbackId="ebwlx1Ty1Dl42MS7vzL7o01bSil6e6vT01eT01aM4k01yfU"
          muted
          autoPlay
          loop
          playsInline
        />
      )}
      <LogoContainer>
        <StyledLogo />
      </LogoContainer>
    </StyledHeader>
  );
}
