{
  "3D_Stage": {
    "Countdown": {
      "days": "days",
      "hours": "hr",
      "minutes": "min"
    },
    "Body_text": "For the <span>25th anniversary of SNIPES</span>, we collaborated with two of our favorite brands - Pegador and Karl Kani - on a premium capsule collection.\nThe result is a pinnacle offering and a not-so-subtle flex to celebrate this milestone the right way.\nMark your calendars for April 4th to be among the first to get your hands on these exclusive, high-quality pieces.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "grey, beige",
        "Link": "https://www.snipes.pt/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "white, grey, orange",
        "Link": "https://www.snipes.pt/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id": "00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "bleached blue",
        "Link": "https://www.snipes.pt/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id": "00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.pt/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id": "00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "coming soon",
      "Active": "buy now"
    }
  },
  "Raffle": {
    "Headline": "win big with snipes clique!",
    "Raffle_body_text": "We want to celebrate our 25th anniversary with you and are raffling off three amazing prizes for members of our SNIPES CLIQUE.\n\nWith some luck, you'll win the big one: an all-around trip to Miami!\n\nBut there are also a VIP ticket package for the Openair Frauenfeld festival and a sneaker flat including twenty-five pairs to be won.\n\nSo don't miss out!\n\nTo participate, all you have to do is join our SNIPES CLIQUE and fill out the raffle form via the button below.",
    "Link": "https://www.snipes.pt/contest-show"
  }
}
