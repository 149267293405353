{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "giorno",
      "hours": "hr",
      "minutes": "min"
    },
    "Body_text": "Per il <span>25° anniversario di SNIPES,</span> abbiamo collaborato con due dei nostri marchi preferiti - Pegador e Karl Kani - per una capsule collection di alto livello.\nIl risultato è un'offerta eccezionale e un'iniziativa non troppo discreta per celebrare questa pietra miliare nel modo giusto.\nSegnate sul calendario il 4 aprile per essere trai primi a mettere le mani su questi articoli esclusivi di altissima qualità.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "grigio, beige",
        "Link": "https://www.snipes.it/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id":"00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "bianco, grigio, arancione",
        "Link": "https://www.snipes.it/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id":"00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "Blu sbiadito",
        "Link": "https://www.snipes.it/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id":"00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.it/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id":"00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "prossimamente",
      "Active": "acquista ora"
    }
  },
  "Editorial": {
    "Editorial_body_text": "Dopo venticinque anni di SNIPES, possiamo guardare con orgoglio a un viaggio straordinario.\nInnumerevoli prodotti esclusivi, collaborazioni sorprendenti ed eventi impareggiabili hanno consolidato un'eredità di grande impatto.\nMa ancora più importante è la famiglia SNIPES, sempre più numerosa, che abbiamo costruito lungo il tragitto e i momenti fantastici che abbiamo vissuto insieme.\nSebbene questo sia un motivo di celebrazione, non è un segno di rallentamento. Continueremo a costruire e a spingere la street culture ancora più in là!."
  },
  "UGC": {
    "UGC_body_text": "L'ispirazione delle comunità di tutte le discipline della cultura streetwear è sempre stata al centro dell'attività di SNIPES.\nSiamo lieti di aver contribuito a costruire ponti tra di esse nel corso degli anni, riunendo creativi appassionatie offrendo loro piattaforme di scambio ed espressione.\nDopo tutto: non c'è cultura senza comunità!"
  },
  "Raffle": {
    "Raffle_body_text": "Vogliamo festeggiare con voi il nostro 25° anniversario e stiamo mettendo in palio tre fantastici premi per i membri della nostra SNIPES CLIQUE.\n\nCon un po' di fortuna, vincerete il premio più importante: un viaggio a tutto tondo a Miami!\n\nCi sono anche un pacchetto di biglietti VIP per il festival Openari Fraunenfeld e un sneaker flat con venticinque paia in palio.\n\nQuindi non perdete l'occasione!\n\nPer partecipare, basta iscriversi alla nostra SNIPES CLIQUE e compilare il modulo della lotteria tramite il tasto qui sotto.",
    "Link":"https://www.snipes.it/contest-show"
  }
}