import * as React from "react";
import { SVGProps } from "react";
import { isMobile } from "../../common/layout";
import { Container, Text } from "./TurnDevice.styles";

export default function TurnDevice(props: SVGProps<SVGSVGElement>) {
  return isMobile() ? (
    <Container>
      <svg width={117} height={117} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={58.5} cy={58.5} r={58} fill="#EA640C" stroke="#494B52" />
        <path
          d="M34.569 34.002a.702.702 0 0 0-.569.7V69.7a.7.7 0 0 0 .7.7h11.9v11.9c0 .386.314.7.7.7h35c.386 0 .7-.314.7-.7V59.902a.7.7 0 0 0-.7-.7H57.8v-24.5c0-.386-.314-.7-.7-.7H34.7a1.027 1.027 0 0 0-.131 0Zm.831 1.4h21V69h-21V35.402Zm3.369 2.8a.7.7 0 0 0-.569.7V62.7a.7.7 0 0 0 .7.7h14a.7.7 0 0 0 .7-.7V38.902c0-.385-.314-.7-.7-.7h-14a1.065 1.065 0 0 0-.131 0Zm28.087.678a.692.692 0 0 0-.372.153l-5.6 4.2a.7.7 0 0 0 0 1.116l5.6 4.2a.702.702 0 0 0 1.04-.093.69.69 0 0 0 .125-.558.703.703 0 0 0-.333-.465l-3.916-2.93h2.231c4.594 0 8.269 3.464 8.269 7.7v1.356l-2.932-3.894a.698.698 0 0 0-.612-.285.702.702 0 0 0-.615.405.705.705 0 0 0 .09.733l4.2 5.6a.7.7 0 0 0 .569.29.7.7 0 0 0 .568-.29l4.2-5.6a.728.728 0 0 0 .137-.528.71.71 0 0 0-1.274-.325L75.3 53.559v-1.357c0-5.042-4.367-9.1-9.669-9.1H63.4l3.916-2.953a.697.697 0 0 0 .251-.81.698.698 0 0 0-.71-.459Zm-27.256.722h12.6V62H39.6V39.602Zm23.034 4.069a.709.709 0 0 0 .022.262l-.197-.13.175-.132ZM57.8 60.603h23.8v21H48v-11.2h1.596c-.12.205-.196.446-.196.7a1.4 1.4 0 1 0 2.8 0c0-.254-.077-.495-.197-.7h1.596v7.7-.003c0 .386.315.7.7.7h23.8c.386 0 .7-.314.7-.7v-14c0-.385-.314-.7-.7-.7H57.8v-2.8.003Zm-11.9 4.2a1.4 1.4 0 1 0 0 2.8 1.4 1.4 0 1 0 0-2.8Zm11.9 0h19.6v12.6H55v-7h2.1V70.4a.7.7 0 0 0 .7-.7v-4.9.003Z"
          fill="#fff"
        />
      </svg>
      <Text>Please turn your device </Text>
    </Container>
  ) : null;
}
