import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import de from './de.json'
import de_DE from './de-DE.json'
import de_AT from './de-AT.json'
import en_PT from './en-PT.json'
import nl_NL from './nl-NL.json'
import fr_FR from './fr-FR.json'
import es_ES from './es-ES.json'
import it_IT from './it-IT.json'
import pl_PL from './pl-PL.json'
import hr_HR from './hr-HR.json'
import nl_BE from './nl-BE.json'
import fr_BE from './fr-BE.json'
import en_HR from './en-HR.json'
import fr_CH from './fr-CH.json'
import de_CH from './de-CH.json'

export function initI18n(locale:string) {
  i18n.use(initReactI18next)
    .init({
      lng: locale,
      debug: false,
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['span', 'br', 'strong', 'i'],
      },
      resources: {
        "en-EN": { translation: en },
        "en-PT": { translation: en_PT },
        "de-DE": { translation: de_DE },
        "de-AT": { translation: de_AT },
        "nl-NL": { translation: nl_NL },
        "fr-FR": { translation: fr_FR },
        "es-ES": { translation: es_ES },
        "it-IT": { translation: it_IT },
        "pl-PL": { translation: pl_PL },
        "hr-HR": { translation: hr_HR },
        "nl-BE": { translation: nl_BE },
        "fr-BE": { translation: fr_BE },
        "en-HR": { translation: en_HR },
        "fr-CH": { translation: fr_CH },
        "de-CH": { translation: de_CH },
        de: { translation: de }
      },
      fallbackLng: {
        "de-AT": ['en-EN', 'de', 'de-AT'],
        "de-DE": ['en-EN', 'de', 'de-DE'],
        "nl-NL": ['en-EN', 'de', 'nl-NL'],
        "fr-FR": ['en-EN', 'de', 'fr-FR'],
        "es-ES": ['en-EN', 'de', 'es-ES'],
        "it-IT": ['en-EN', 'de', 'it-IT'],
        "pl-PL": ['en-EN', 'de', 'pl-PL'],
        "hr-HR": ['en-EN', 'de', 'hr-HR'],
        "en-PT": ['en-EN', 'de', 'en-PT'],
        "nl-BE": ['nl-NL', 'nl-BE', 'en-EN'],
        "fr-BE": ['fr-FR', 'fr-BE', 'en-EN'],
        "en-HR": ['en-EN', 'de', 'en-HR'],
        "fr-CH": ['fr-FR', 'fr-CH', 'en-EN'],
        "de-CH": ['de', 'de-CH', 'en-EN'],
        // default: ['en-EN', 'de', 'de-DE'],
      },
    });
}

export default i18n
