import styled from "styled-components";
import { StyledButton, vp } from "../../common/layout";
import NoiseTexture from "../../assets/img/Grey-bottom-gradient.jpg";

export const NoiseOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-image: url(${NoiseTexture});
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
`;

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${vp.small(`
    height: max(150vh, 100%);
    padding-bottom: 5rem;
  `)}
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  width: 100%;
  height: 100%;
  z-index: 1;
  align-items: center;

  ${vp.small(`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  `)}

  @media (min-width: 1025px) and (max-width: 1280px) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

export const Heading = styled.h4`
  font-size: 3rem;
  margin-bottom: 0;

  ${vp.small(`
    margin-top: 5rem;
    font-size: 48px;
  `)};
`;

export const Text = styled.p`
  color: white;
  font-size: 16px;
  line-height: 20px;
  max-width: 50ch;
  margin-bottom: 3rem;
  white-space: pre-line;

  ${vp.small(`
    font-size: 14px;
    max-width: 40ch;
  `)}
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10%;

  ${vp.small(`
    margin: 0  1.5rem;
  `)}
`;

export const LeftColumn = styled(Column)`
  img {
    display: none;

    ${vp.small(`
      display: block;
      width: 100%;
    `)}

    @media (min-width: 1025px) and (max-width: 1280px) and (orientation: portrait) {
      display: block;
      width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: block;
      width: 100%;
  }
  }
`;

export const RightColumn = styled(Column)`
  margin: 0;
  align-items: center;
  justify-content: start;

  ${vp.small(`
    display: none;
  `)}

  @media (min-width: 1025px) and (max-width: 1280px) and (orientation: portrait) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }

  img {

    ${vp.small(`
      width: 320px;
    `)}

    ${vp.medium(`
      width: 100%;
    `)}
    
    ${vp.large(`
      width: 1000px;
    `)}

    ${vp.extraLarge(`
      width: 1200px;`)}
  }
`;

export const RaffleButton = styled(StyledButton)`
  max-width: 250px;

  ${vp.small(`
    align-self: center;
    max-width: 100%;
    `)}

  @media (min-width: 1025px) and (max-width: 1280px) {
    align-self: center;
    max-width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    align-self: center;
    max-width: 100%;
  }
`;
