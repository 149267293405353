import React, { useEffect, useRef } from "react";
import { Group, ShaderChunk } from "three";
import gsap, { Power1, Circ } from "gsap";
import { useFrame, useThree } from "@react-three/fiber";
import { Environment, Float, useGLTF } from "@react-three/drei";
import { ScrollState } from "@14islands/r3f-scroll-rig";
import { Fog } from "three/src/scenes/Fog";
import shirtUrl from "../../assets/glb/shirt_fixedNormals.glb";
import pantUrl from "../../assets/glb/pant_2.glb";
import sneakerUrl from "../../assets/glb/sneaker.glb";
import jacketUrl from "../../assets/glb/jacket_2.glb";
import hdr1Url from "../../assets/hdr/06_shader.hdr";
import { breakPoints } from "../../common/layout";
import useUiStore from "../../stores/useUiStore";
// overriding the fog shader
// console.log(ShaderChunk.fog_vertex)
// console.log(ShaderChunk.fog_fragment)

ShaderChunk.fog_vertex = `
  #ifdef USE_FOG
    vFogDepth = -mvPosition.z;
  #endif
`;
ShaderChunk.fog_fragment = `
  #ifdef USE_FOG
    // compute fog and apply to alpha
    gl_FragColor.a = 1.0 - clamp(smoothstep(fogNear, fogFar, vFogDepth), 0.0, 1.0);

    // as we are working in a premultiplied pixel environment
    // we have to multiply rgb with alpha to retain the colors
    gl_FragColor.r = gl_FragColor.r * gl_FragColor.a;
    gl_FragColor.g = gl_FragColor.g * gl_FragColor.a;
    gl_FragColor.b = gl_FragColor.b * gl_FragColor.a;
  #endif
`;

export interface ProductsProps {
  scrollState: ScrollState;
}

function Product({ glbRef, url, rotation = [0, 0, 0], scale = 1 }) {
  const glb = useGLTF(url);

  const debugTransform = false;
  const debugShading = false;
  return (
    <group ref={glbRef} scale={scale}>
      <Float floatIntensity={0.2} rotationIntensity={0.15}>
        {debugTransform && (
          <mesh position={[0, 0, 0]} scale={1}>
            <boxGeometry args={[0.3, 0.3, 0.3]} />
            <meshStandardMaterial color="red" transparent opacity={0.6} wireframe />
          </mesh>
        )}
        {debugShading && (
          <mesh position={[0, 0, 0]} scale={1}>
            <sphereGeometry args={[0.3, 20, 20]} />
            <meshStandardMaterial color="white" roughness={0.3} metalness={1} />
          </mesh>
        )}
        <primitive object={glb.scene} rotation={rotation} />
      </Float>
    </group>
  );
}

export function Products({ scrollState }: ProductsProps) {
  const { scene } = useThree();

  const tlRef = useRef<gsap.core.Timeline>(null!);
  const productContainerRef = useRef<Group>(null!);
  const box1 = useRef<Group>(null!);
  const box2 = useRef<Group>(null!);
  const box3 = useRef<Group>(null!);
  const box4 = useRef<Group>(null!);

  useEffect(() => {
    // create timeline that is controlled by scroll progress
    const tl = gsap.timeline({ autoplay: false });
    tlRef.current = tl;
    tl.pause();

    const addSingleProductAnimation = (o: Group, index: number) => {
      // single anim configuration => must be duration of 3 otherwise offset does not work

      // 0 = start, 1 = center, 2 = end
      const x0 = 1;
      const x1 = 0;
      const x2 = -3;

      const y0 = -0.5;
      const y1 = 0;
      const y2 = -1.5;

      const z0 = 1;
      const z1 = 0;
      const z2 = -2;

      // 0 => 0 inner => 2 inner => 2
      const r0 = -2;
      const r0inner = -4;
      const r2inner = 4;
      const r2 = 2;

      const offset = index * 0.7 * 3;

      // init
      tl.fromTo(o.position, { x: x0 }, { x: x0, duration: offset }, 0);
      tl.fromTo(o.position, { y: y0 }, { y: y0, duration: offset }, 0);
      tl.fromTo(o.position, { z: z0 }, { z: z0, duration: offset }, 0);
      tl.fromTo(o.rotation, { y: -2 }, { y: -2, duration: offset }, 0);

      // translate in
      tl.fromTo(o.position, { x: x0 }, { x: x1, duration: 1, ease: Power1.easeOut }, 0 + offset);
      tl.fromTo(o.position, { y: y0 }, { y: y1, duration: 1, ease: Circ.easeOut }, 0 + offset);
      tl.fromTo(o.position, { z: z0 }, { z: z1, duration: 1, ease: Power1.easeOut }, 0 + offset);

      // overlapping roation
      const ex = 0.3;
      tl.fromTo(
        o.rotation,
        { y: r0 },
        { y: r0inner, duration: 1 - ex, ease: Power1.easeOut },
        0 + offset
      );
      tl.fromTo(
        o.rotation,
        { y: r0inner },
        { y: r2inner, duration: 1 + ex * 2, ease: Power1.easeInOut },
        1 - ex + offset
      );
      tl.fromTo(
        o.rotation,
        { y: r2inner },
        { y: r2, duration: 1 - ex, ease: Power1.easeIn },
        2 + ex + offset
      );

      // translate out
      tl.fromTo(o.position, { x: x1 }, { x: x2, duration: 1, ease: Power1.easeIn }, 2 + offset);
      tl.fromTo(o.position, { y: y1 }, { y: y2, duration: 1, ease: Circ.easeIn }, 2 + offset);
      tl.fromTo(o.position, { z: z1 }, { z: z2, duration: 1, ease: Power1.easeIn }, 2 + offset);
    };

    addSingleProductAnimation(box1.current, 0);
    addSingleProductAnimation(box2.current, 1);
    addSingleProductAnimation(box3.current, 2);
    addSingleProductAnimation(box4.current, 3);
  }, []);

  useFrame(() => {
    if (!tlRef.current) return;

    // adding some space before anim starts and after anim ends
    // scrollOversize in relation to 1
    // scrollOversize of 2 means anim with a half of scrollState.progress
    const scrollOversize = 1.05;
    const offset = (scrollOversize - 1) / 2;
    const animProgress = scrollState.progress * scrollOversize - offset;
    const hackyMinimumProgress = 0.0001;
    const animProgressNormalized = Math.min(Math.max(animProgress, hackyMinimumProgress), 1);
    tlRef.current.progress(animProgressNormalized);

    // r3f-scroll-rig is magically moving the scene towards z axis
    // to have a reliable fog we have to reconfigure
    const magicZ = scene.children[0].position.z;
    const fog = scene.fog as Fog;
    fog.near = magicZ + 20;
    fog.far = magicZ + 80;

    // r3f-scroll-rig cannot handle a non full width canvas as we are using it.
    // so we have to move out content to fix the offset
    const magicOffsetX = scene.children[1].position.x;

    // onFrame responsiveness handling
    if (productContainerRef.current) {
      const isSmall = window.innerWidth < breakPoints.small.max;
      if (isSmall) {
        productContainerRef.current.position.x = 0;
        productContainerRef.current.position.y = 1;
        productContainerRef.current.scale.setScalar(50);
      } else {
        productContainerRef.current.position.x = 24.5 - magicOffsetX;
        productContainerRef.current.position.y = 1;
        productContainerRef.current.scale.setScalar(70);
      }
    }
  });
  useFrame(() => {
    const setJacket = scrollState.visibility > -0.1 && scrollState.visibility < 0.24;
    const setShoes = scrollState.visibility > 0.25 && scrollState.visibility < 0.49;
    const setPants = scrollState.visibility > 0.5 && scrollState.visibility < 0.74;
    const setShirt = scrollState.visibility > 0.75 && scrollState.visibility < 1;
    const { selectedProductIndex } = useUiStore.getState();

    if (setJacket && selectedProductIndex !== 0) {
      useUiStore.getState().setSelectedProductIndex(0);
    }
    if (setShoes && selectedProductIndex !== 1) {
      useUiStore.getState().setSelectedProductIndex(1);
    }
    if (setPants && selectedProductIndex !== 2) {
      useUiStore.getState().setSelectedProductIndex(2);
    }
    if (setShirt && selectedProductIndex !== 3) {
      useUiStore.getState().setSelectedProductIndex(3);
    }

    // console.log("A:", useUiStore.getState().selectedProduct);
    // console.log("B:", scrollState.viewport, scrollState.visibility);
  });

  useFrame(() => {
    const viewabilityStateIn = scrollState.visibility < 1 && scrollState.viewport > 1;
    if (useUiStore.getState().archVisible !== viewabilityStateIn) {
      useUiStore.getState().setArchVisible(viewabilityStateIn);
    }
    // console.log("viewabilityIn", viewabilityIn)
  });

  return (
    <>
      <Environment files={hdr1Url} />
      <group ref={productContainerRef}>
        <Product glbRef={box1} url={jacketUrl} rotation={[0, -Math.PI / 2, 0]} scale={1.1} />
        <Product glbRef={box2} url={sneakerUrl} rotation={[-0.4, Math.PI, 0]} scale={1.7} />
        <Product glbRef={box3} url={pantUrl} rotation={[0, -Math.PI, 0]} scale={0.8} />
        <Product glbRef={box4} url={shirtUrl} rotation={[0, -Math.PI / 2, 0]} scale={1.0} />
      </group>
    </>
  );
}
