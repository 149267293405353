import styled from "styled-components";
import { vp, } from "../../common/layout";
import BrushTextureImg from "../../assets/img/Brush-texture-2x.png";
import PaperTextureImg from "../../assets/img/Paper-texture-2x.png";
import MapPaperTextureImg from "../../assets/img/Map-paper-texture-2x.jpg";
import Scribble from "../../assets/img/Scribble.png";

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  scroll-snap-stop: always;
`;

export const MapContainer = styled.div`
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${MapPaperTextureImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  background-position: center;
  /* filter: contrast(1.35); */
`;

export const HeadingsContainer = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
  margin-top: 5.3rem;

  ${vp.small(`
  margin-top: -2.8rem;
    
    `)}

  ${vp.medium(`
    margin-top: 4rem;
   
    `)}

  ::after {
    content: url(${Scribble});
    position: absolute;
    inset: 45% auto 0;
    z-index: -1;
    scale: 0.7;

    ${vp.small(`
    scale: 0.35;
    inset: 22% auto 0;
    `)}

    ${vp.medium(`
    scale: 0.6;
    inset: 40% auto 0;
    `)}
  }
`;

export const TitleHeadline = styled.h2`
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 500;
  margin: -50px 0 0 0;
  opacity: 0;

  ${vp.small(`
    font-size: 3rem;
    margin: -5px 0 0 0;
    `)}
  ${vp.medium(`
    font-size: 4.7rem;
    `)}
`;

export const SubHeadline = styled.h3`
  font-size: 50px;
  font-weight: 500;
  margin: -40px 0 0 0;
  opacity: 0;
  letter-spacing: 0.2rem;

  ${vp.small(`
    margin-top: -10px;
    `)}
`;

export const CountHeadline = styled.h1`
  font-size: 400px;
  font-weight: 500;
  letter-spacing: 2.5rem;
  padding: 0;
  margin: 0;

  ${vp.small(`
    font-size: 9rem;
    margin: 0 0 0 40px;
    `)}
  ${vp.medium(`
    font-size: 14rem;
    `)}
`;

export const OrangeHeadline = styled.h4`
  font-size: 3rem;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.2rem;
  opacity: 0;

  ${vp.small(`
    font-size: 1.6rem;

    `)}
  ${vp.medium(`
    font-size: 2.5rem;
    `)}
`;

export const OrangeBar = styled.div`
  background: linear-gradient(90deg, rgba(255, 178, 128, 1) 0%, rgba(255, 108, 1, 1) 100%);
  width: 100%;
  height: 5.3rem;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  z-index: 2;

  ${vp.small(`
  height: 2.8rem;
    `)}
  ${vp.medium(`
  height: 4rem;
    `)}
`;

export const BrushTexture = styled.div`
  background: url(${BrushTextureImg});
  position: absolute;
  background-repeat: no-repeat;
  top: -20px;
  left: 100px;
  width: 100%;
  height: 160px;
  scale: 1.15;
  z-index: 1;

  ${vp.small(`
    top: -65px;
    left: 0;
    `)}
  ${vp.medium(`
    scale: 1;
    left: 0;
    `)}
`;

export const PaperTexture = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;
  z-index: 2;
  
  background: url(${PaperTextureImg});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  
  ${vp.small(`
    top: 0;
    left: 0;
    background-size: 280%;
  `)}
  ${vp.medium(`
    top: -10px;
  `)}
`;

export const AnimatedHeadline = styled.div`
  color: black;
  font-size: 1.6rem;
  font-weight: 500;
  z-index: 1;
  padding: 0;
  align-self: center;

  ${vp.small(`
    font-size: 1.2rem;
    `)}
  ${vp.medium(`
    font-size: 1.4rem;
    `)}
`;
