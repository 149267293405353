{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "days",
      "hours": "hr",
      "minutes": "min"
    },
    "Body_text": "Za <span>25. godišnjicu SNIPES-a,</span> surađivali smo s naša dva omiljena brenda – Pegador i Karl Kani – na premium capsule kolekciji.\nRezultat je vrhunska ponuda i ne tako suptilni flex za proslavu ove prekretnice na pravi način.\nObilježi svoj kalendar za 4. travnja kako bi među prvima dobio/la ove ekskluzivne, visokokvalitetne artikle.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "siva, bež",
        "Link": "https://www.snipes.hr/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "bijela, sivo, narančast",
        "Link": "https://www.snipes.hr/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id": "00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "isprano plava",
        "Link": "https://www.snipes.hr/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id":"00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "bež",
        "Link": "https://www.snipes.hr/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id": "00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "USKORO",
      "Active": "KUPI SADA"
    }
  },
  "Editorial": {
    "Editorial_body_text": "Nakon dvadeset i pet godina SNIPES-a, možemo se ponosno osvrnuti na nevjerojatno putovanje.\nBezbrojni ekskluzivni proizvodi, iznenađujuće suradnje i događaji bez premca učvrstili su naš legacy.\nAli još važnije: stalno rastuća obitelj SNIPES koju smo usput izgradili i sjajna vremena koja smo proživjeli zajedno.\nIako je ovo razlog za slavlje, nije znak da usporimo. Nastavit ćemo graditi i gurati uličnu kulturu još dalje!"
  },
  "UGC": {
    "UGC_body_text": "Inspirativne zajednice u svim disciplinama ulične kulture uvijek su bile u središtu onoga što SNIPES radi.\nDrago nam je što smo godinama pomogli u izgradnji mostova između njih - okupljajući strastvene kreativce i pružajući im platforme za razmjenu i izražavanje.\nAfter all: there's no culture without community!"
  },
  "Raffle": {
    "Raffle_body_text": "Želimo proslaviti našu 25. obljetnicu s tobom i izvlačimo tri nevjerojatne nagrade za naše SNIPES CLIQUE članove.\n\nUz malo sreće, osvojit ćeš glavnu nagradu: povratno putovanje u Miami!\n\nAli tu su i neki VIP paketi ulaznica za Openair Frauenfeld festival i čak dvadeset i pet pari tenisica koje možeš osvojiti.\n\nSo don't miss out!\n\nSve što je potrebno za sudjelovanje je pridružiti se našoj SNIPES CLIQUE i ispuniti obrazac za nagradnu igru putem gumba ispod.",
    "Link":"https://www.snipes.hr/contest-show"
  }
}