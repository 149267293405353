import React, { useEffect } from "react";
// import styled from "styled-components";
import gsap from "gsap";
import useUiStore from "../../stores/useUiStore";
import ProductBgImg from "../../assets/img/3d-stage-product-bg-1x.png";
import { BackgroundContainer, NoiseOverlay } from "./BackgroundContainer.styles";

function BackGroundContainer() {
  const archVisible = useUiStore((state) => state.archVisible);
  const imageRef = React.useRef<HTMLImageElement>(null);

  useEffect(() => {
    const tl = gsap.timeline();
    if (imageRef.current) {
      if (archVisible) {
        tl.fromTo(
          imageRef.current,
          {
            opacity: 0,
            y: 700,
            x: 400
          },
          {
            opacity: 1,
            y: 0,
            x: 0,
            ease: "power4.out",
            duration: 1
          }
        );
      } else {
        tl.to(imageRef.current, {
          opacity: 0,
          y: 700,
          x: 400
        });
      }
    }
  }, [archVisible]);

  return (
    <BackgroundContainer>
      <NoiseOverlay />
      <img ref={imageRef} src={ProductBgImg} alt="bg" />
    </BackgroundContainer>
  );
}

export default BackGroundContainer;
