import React from "react";
import { Container, StyledSection } from "./CampaignVideo.styles";
import useUiStore from "../../stores/useUiStore";

export default function CampaignVideo() {
  // const isTimeUp = useUiStore((state) => state.isTimeUp);
  // Quick and dirty fix.
  const isTimeUp = new Date("April 4, 2023 12:00:00").getTime() <= (new Date()).getTime();
  return isTimeUp ? (
    <StyledSection>
      <Container>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/kCXg2lRBZV8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Container>
    </StyledSection>
  ) : null;
}
