import React from "react";
import { Trans, useTranslation } from "react-i18next";

import CountDown from "../CountDown/CountDown";
import { Column, Container, Headline, Text } from "./ProductsInfo.styles";

export default function ProductsInfo() {
  const { t, ready } = useTranslation();

  return (
    <Container>
      <CountDown />
      <Column>
        <Headline>
          Celebrating
          <br />
          25 years in style
        </Headline>
        <Text>
          {ready && <Trans i18nKey="3D_Stage.Body_text" />}
        </Text>
      </Column>
    </Container>
  );
}
