import React from "react";
import "../../styles.css";
import Header from "../Header/Header";
import Stage from "../Stage/Stage";
import CampaignMovie from "../CampaignVideo/CampaignVideo";
import Editorial from "../EditorialContent/EditorialContent";
import Raffle from "../Raffle/Raffle";
import ExpansionScrolly from "../Expansion/Expansion";
import UGC from "../UGC/UGC";
import ProductsInfo from "../ProductsInfo/ProductsInfo";
import BackGroundContainer from "../BackgroundContainer/BackgroundContainer";
import { StyledCanvas, StyledMain, StyledSmoothScrollbar, AppContainer } from "./Page.styles";
import useConfigStore from "../../stores/useConfigStore";
import TurnDevice from "../TurnDevice/TurnDevice";

export default function Page() {
  const noRaffle = useConfigStore((state) => state.noRaffle);

  // isSoldOut reverses the order of stage and raffle
  // if sold out, raffle is first
  const isSoldOut = useConfigStore((state) => state.isSoldOut);
  const sectionStage = (
    <>
      <ProductsInfo />
      <Stage />
    </>
  );
  const sectionRaffle = !noRaffle && <Raffle />;
  const [sectionTop, sectionBottom] = isSoldOut
    ? [sectionRaffle, sectionStage]
    : [sectionStage, sectionRaffle];

  return (
    <AppContainer>
      <TurnDevice />
      <BackGroundContainer />
      <StyledCanvas scaleMultiplier={0.1}>
        <fog attach="fog" color="hotpink" near={0.1} far={400} />
      </StyledCanvas>
      <Header />
      <StyledSmoothScrollbar>
        {(bind: React.Attributes) => (
          <StyledMain {...bind}>
            <ExpansionScrolly />
            {sectionTop}
            <CampaignMovie />
            <Editorial />
            <UGC />
            {sectionBottom}
          </StyledMain>
        )}
      </StyledSmoothScrollbar>
    </AppContainer>
  );
}
