import styled, { css } from "styled-components";
import { StyledButton, vp } from "../../common/layout";
import OrangeCircle from "../../assets/img/Orange-circle.png";
import ScribbleGraffiti from "../../assets/svg/ScribbleGraffiti";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: white;

  ${vp.small(`
    display: flex;
    flex-direction: column;
    height: 150vh;
  `)};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10%;
  img {
    margin: 0 auto;
  }
`;

export const LeftColumn = styled(Column)`
  position: relative;
  margin-top: 20%;
  > :nth-child(3) {
    position: absolute;
    scale: 0.9;
    bottom: 150px;
    right: -90px;

    ${vp.medium(`
    scale: 0.8;
    bottom: 15px;
  `)};

  ${vp.large(`
    scale: 0.9;
    bottom: 8vh;
    right: -5vw;
  `)}

  ${vp.extraLarge(`
    scale: 1.2;
    bottom: 20%;
    right: 75px;
  `)}
  }

  > :nth-child(4) {
    position: absolute;
    scale: 0.8;
    bottom: 80px;
    left: -170px;

    ${vp.extraLarge(`
    scale: 1;
    transform: translate(-235px, -125px);
  `)}
  }

  ${vp.small(`
    display: none;
  `)};

  
`;

export const Headline = styled.h1`
  font-size: 3rem;
  max-width: 15ch;
  margin: 0 0 1.2rem 0;

  ::after {
    content: url(${OrangeCircle});
    position: absolute;
    top: 15px;
    left: 185px;
    z-index: -1;
    scale: 1.4;
  }

  ${vp.small(`
    margin-top: 5rem;
    font-size: 2rem;
  `)};
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 50ch;
  margin: 0.3rem;
  white-space: pre-line;
  span {
    color: #ea640c;
    margin: 0 0.2rem;
  }

  ${vp.small(`
 font-size: 14px;
`)};
`;

export const RadialGradient = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  top: 150px;
  left: -220px;
  background-image: radial-gradient(
    circle at center,
    #ea650c6b 1%,
    transparent 70%,
    transparent 100%
  );

  ${vp.extraLarge(`
    scale: 1.2;
    transform: translate(-235px, 125px);
  `)}
`;

export const ButtonContainer = styled.div`
  min-width: 325px;
  display: flex;
  flex-direction: column;
  margin: 0 ;
  width: fit-content;
  position: absolute;
  bottom: 120px;
  z-index: 10;

  ${vp.small(`
  bottom: 100px;
  scale: 0.8;
  width: 90%;

  >button{
  width: 85%;
  }
`)};
`;

export const ProductDescription = styled(Text)`
  color: #848484;
`;

export const StyledPaperTextureImg = styled.img`
  transform: rotate(180deg);
  height: 120px;
  width: 100%;
  position: fixed;
  bottom: -5px;
`;
export const Wrapper = styled.div`
  ${vp.small(`
  display: none;`)}
`;

export const MobileScribbleGraffiti = styled(ScribbleGraffiti)`
display: none;
width: 100%;
rotate: -15deg;
scale: 0.55;
  ${vp.small(`
  display: block;
  position: absolute;
  bottom: -6vh;
  left: -46VW;`)}
`

export const ProductsButton = styled(StyledButton)<{ isTimeUp: boolean }>`
  ${({ isTimeUp }) => isTimeUp
    ? css``
    : css`
          background-color: #DBDBDC;
          pointer-events: none;
          color:#494B52 ;
        `}
`;
