// eslint-disable-next-line @typescript-eslint/quotes
import React from "react";
import { useTranslation } from "react-i18next";
import useConfigStore from "../../stores/useConfigStore";
import PriceImg from "../../assets/img/Raffle-prizes.png";
import {
  Container,
  Heading,
  LeftColumn,
  NoiseOverlay,
  RaffleButton,
  RightColumn,
  StyledSection,
  Text,
  TextContainer
} from "./Raffle.styles";

export default function Raffle() {
  const { t } = useTranslation();
  const RaffleLink = t("Raffle.Link");
  const isCliqueNotAvailable = useConfigStore((state) => state.isCliqueNotAvailable);

  return !isCliqueNotAvailable ? (
    <StyledSection>
      <NoiseOverlay />
      <Container>
        <LeftColumn>
          <img src={PriceImg} alt="price" />

          <TextContainer>
            <Heading>
              win big with the
              <br />
              snipes clique!
            </Heading>
            <Text>{t("Raffle.Raffle_body_text")}</Text>
            <RaffleButton role="link" onClick={() => window.open(RaffleLink, "_blank")}>
              join raffle now
            </RaffleButton>
          </TextContainer>
        </LeftColumn>
        <RightColumn>
          <img src={PriceImg} alt="price" />
        </RightColumn>
      </Container>
    </StyledSection>
  ) : null;
}
