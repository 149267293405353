{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "dni",
      "hours": "godziny",
      "minutes": "minuty"
    },
    "Body_text": "Z okazji <span>25-lecia SNIPES,</span> współpracowaliśmy z dwoma naszymi najgorętszymi markami - Pegador i Karl Kani - nad kapsułową kolekcją premium.\nRezultatem jest wyjątkowy drop, aby uczcić ten kamień milowy.\nZaznaczcie w kalendarzach 4 kwiecień, aby być jednymi z pierwszych, którzy dostaną w swoje ręce te ekskluzywne, premium produkty.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "szary, beż",
        "Link": "https://www.snipes.pl/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id": "00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "biały, szary, pomarańczowy",
        "Link": "https://www.snipes.pl/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id": "00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "jasny błękiti",
        "Link": "https://www.snipes.pl/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id":"00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beż",
        "Link": "https://www.snipes.pl/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id": "00013802218098"
      }
    ],
    "Product_CTA": {
      "Inactive": "JUŻ WKRÓTCE",
      "Active": "KUP TERAZ"
    }
  },
  "Editorial": {
    "Editorial_body_text": "Po dwudziestu pięciu latach istnienia SNIPES, możemy z dumą spojrzeć wstecz na niesamowitą podróż.\nNiezliczone ekskluzywne produkty, zaskakujące kolaboracje i bezkonkurencyjne wydarzenia ugruntowały nasze wpływowe dziedzictwo.\nNadal ważne są: stale rosnąca rodzina SNIPES, którą zbudowaliśmy w trakcie wspólnej prztygody i wspaniałe chwile, które razem przeżyliśmy.\nChoć już mamy powody do świętowania, nie jest to dla nas znak, że mamy zwolnić tempo. Będziemy nadal budować i aktywować kulturę uliczną jeszcze dalej!."
  },
  "UGC": {
    "UGC_body_text": "Inspirujące społeczności wszystkich typów kultury ulicznej zawsze były w sercu tego, co robi SNIPES.\nCieszymy się, że przez lata pomagaliśmy budować mosty między nimi - łącząc pasjonatów twórczości i zapewniając im platformy do wymiany i ekspresji.\nW końcu: nie ma kultury bez społeczności!"
  },
  "Raffle": {
    "Raffle_body_text": "Chcemy razem z Wami świętować nasze 25 lecie i przeznaczamy na loterię trzy niesamowite nagrody dla członków naszego SNIPES CLIQUE.\n\nMasz szansę powalczyć o tą największą: wycieczkę do Miami!\n\nDo wygrania jest również pakiet biletów VIP na festiwal Openair Frauenfeld oraz 25 par sneakersów typu flat.\n\nNie przegap!\n\nAby wziąć udział, wystarczy dołączyć do naszego SNIPES CLIQUE i wypełnić formularz loterii za pomocą przycisku poniżej.",
    "Link":"https://www.snipes.pl/contest-show"
  }
}
