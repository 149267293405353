/* eslint-disable */
import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { StickyScrollScene } from "@14islands/r3f-scroll-rig/powerups";
import { UseCanvas } from "@14islands/r3f-scroll-rig";
import { isMobile, StickyContainer, TrackerContainer } from "../../common/layout";
import { Products, ProductsProps } from "../Products/Products";
import Stone from "../../assets/img/Stage-stone.png";
import ScribbleGraffiti from "../../assets/svg/ScribbleGraffiti";
import PaperTextureImg from "../../assets/img/Paper-texture-2x.png";
import useUiStore from "../../stores/useUiStore";
import useConfigStore from "../../stores/useConfigStore";
import CountDown from "../CountDown/CountDown";
import { useTranslation, Trans } from "react-i18next";
import {
  ButtonContainer,
  Column,
  Container,
  Headline,
  LeftColumn,
  ProductDescription,
  ProductsButton,
  RadialGradient,
  StyledPaperTextureImg,
  Text,
  Wrapper,
  MobileScribbleGraffiti
} from "./Stage.styles";

export default function Stage() {
  const el = useRef<HTMLDivElement>(null);
  const selectedProductIndex = useUiStore((state) => state.selectedProductIndex);
  const showPaperTexture = useUiStore((state) => state.archVisible);
  const isTimeUp = useUiStore((state) => state.isTimeUp);
  const { t, ready } = useTranslation();
  const isClique = useConfigStore((state) => state.isClique);

  const productTexts = t("3D_Stage.Products", { returnObjects: true }) as any[];

  const getUrl = () => {
    const link = productTexts[selectedProductIndex].Link;
    const id = productTexts[selectedProductIndex].id;

    isClique
      ? window.SnipesApp.navigate("ShopStack", { screen: "PDPScreen", params: { productId: id } })
      : window.open(link, "_blank");
  };

  return (
    <section>
      <StickyContainer>
        <TrackerContainer ref={el}>
          <Wrapper>
            <CountDown />
          </Wrapper>
          <Container>
            <LeftColumn>
              <Headline>
                Celebrating
                <br />
                25 years in style
              </Headline>
              <Text>{ready && <Trans i18nKey="3D_Stage.Body_text" />}</Text>
              <img src={Stone} alt="stone" />
              <ScribbleGraffiti />
              <RadialGradient />
            </LeftColumn>
            <Column>
              <ButtonContainer>
                <Text>{productTexts && productTexts[selectedProductIndex].Name}</Text>
                <Text>{productTexts && productTexts[selectedProductIndex].Description}</Text>
                <ProductDescription>
                  {productTexts && productTexts[selectedProductIndex].Color}
                </ProductDescription>
                <ProductsButton isTimeUp={isTimeUp} role="link" onClick={() => getUrl()}>
                  {isTimeUp ? t("3D_Stage.Product_CTA.Active") : t("3D_Stage.Product_CTA.Inactive")}
                </ProductsButton>
              </ButtonContainer>
              <MobileScribbleGraffiti />
            </Column>
          </Container>
          {showPaperTexture && <StyledPaperTextureImg src={PaperTextureImg} alt="paper" />}
        </TrackerContainer>
      </StickyContainer>
      <UseCanvas>
        <StickyScrollScene track={el}>
          {(props: ProductsProps) => <Products {...props} />}
        </StickyScrollScene>
      </UseCanvas>
    </section>
  );
}
