{
  "Expansion_Scrolly": {
    "Orange_bar_text": ""
  },
  "3D_Stage": {
    "Countdown": {
      "days": "dagen",
      "hours": "hr",
      "minutes": "min"
    },
    "Body_text": "Voor de <span>25th anniversary van SNIPES</span> hebben we samengewerkt met twee van onze favorite brands - Pegador en Karl Kani - aan een premium capsule collection.\nHet resultaat is een topaanbod en een not-so subtle flex om deze milestone op de juiste manier te vieren.\nZet 4 april in je agenda om als een van de eersten deze exclusieve, high-quality pieces in handen te krijgen.",
    "Products": [
      {
        "Name": "Pegador x SNIPES",
        "Description": "Anniversary College Jacket",
        "Color": "grijs, beige",
        "Link": "https://www.snipes.nl/p/pegador-college_jacket_grey_cream-grau-00013802218089.html",
        "id":"00013802218089"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "LXRY PLUS PRM LTR",
        "Color": "wit, grijs, oranje",
        "Link": "https://www.snipes.nl/p/karl_kani-lxry_plus_prm_ltr_white-weiss-00013802211121.html",
        "id":"00013802211121"
      },
      {
        "Name": "Karl Kani x SNIPES",
        "Description": "Anniversary Monogram Baggy Pants",
        "Color": "bleached blauw",
        "Link": "https://www.snipes.nl/p/karl_kani-signature_denim_baggy_workwear_pants_bleached_blue-blau-00013802221813.html",
        "id":"00013802221813"
      },
      {
        "Name": "Pegador x SNIPES",
        "Description": "Cali Logo Collab Tee",
        "Color": "beige",
        "Link": "https://www.snipes.nl/p/pegador-cali_logo_colab_tee-washed_cream-00013802218098.html",
        "id":"00013802218098"
      }
    ]
  },
  "Editorial": {
    "Editorial_body_text": "Na vijfentwintig jaar SNIPES kunnen we met trots terugkijken op een geweldige journey.\nTalloze exclusieve items, verrassende collabs en ongeëvenaarde evenementen hebben een impactful legacy verstevigd.\nEven more important: de steeds groter wordende SNIPES family die we on the way hebben opgebouwd en de geweldige tijden die we samen hebben beleefd.\nHoewel dit een reason to party is, is het voor ons geen teken om het rustiger aan te doen. We blijven de street culture verder uitbouwen en pushen!"
  },
  "UGC": {
    "UGC_body_text": "De inspiring communities in alle vormen van de street culture hebben altijd centraal gestaan ​​in wat SNIPES doet.\nWe zijn blij dat we door de jaren heen hebben geholpen om bruggen tussen hen te bouwen door gepassioneerde creatives samen te brengen en hen platforms te bieden voor exchange en expression.\nAfter all: no culture without community!"
  },
  "Raffle": {
    "Raffle_body_text": "We willen onze 25th anniversary met jullie vieren, daarom verloten we drie geweldige prijzen voor members van onze SNIPES CLIQUE.\n\nMet een beetje geluk win je de biggest: een allround trip naar Miami!\n\nMaar ook kun je een VIP-ticketpakket voor het Openair Frauenfeld-festival en een sneaker flat met vijfentwintig pairs winnen.\n\nDon't miss out!\n\nOm mee te doen hoef je alleen maar member te worden van onze SNIPES CLIQUE en het raffle formulier in te vullen via onderstaande button.",
    "Link":"https://www.snipes.nl/contest-show"
  }
}
