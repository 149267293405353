import styled from "styled-components";
import { GlobalCanvas, SmoothScrollbar } from "@14islands/r3f-scroll-rig";
import { vp } from "../../common/layout";

export const StyledSmoothScrollbar = styled(SmoothScrollbar)`
  width: 100%;
`;

export const StyledMain = styled.main`
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
`;

export const StyledCanvas = styled(GlobalCanvas)`
  z-index: -1;
  max-width: 2000px;
  margin: 0 auto;
  ${vp.small(`
    max-width: 100%;
  `)};
`;

export const AppContainer = styled.div`
  > * {
    font-family: 'Highman', sans-serif;
    color: white;
    line-height: normal;
}

  h1, h2, h3, h5, h6 {
    font-family: 'Highman', sans-serif;
    color: white;
    line-height: normal;
}
h4{
  font-family: 'Highman', sans-serif;
  color: #ea640c;
  line-height: normal;
}

  p {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

img {
  height: unset;
  max-width: unset;
  vertical-align: unset;
}

button {
  font-family: 'Rubik', sans-serif;
}

`
