import styled from "styled-components";
import { Overlay, vp } from "../../common/layout";

export const StyledSection = styled.section`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;


  .paper {
    position: absolute;
    width: 100%;
    top: 0;
  }
`;

export const EditorialOverlay = styled(Overlay)`
  height: 40%;
  z-index: 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  img {
    place-self: center;

    ${vp.small(`
    width: 90%;
`)};
  }

  ${vp.small(`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
`)};
`;

export const Headline = styled.h4`
  font-size: 3rem;
  max-width: 20ch;
  margin-bottom: 1rem;
  text-transform: uppercase;

  ${vp.small(`
    font-size: 48px;
`)};
`;

export const TextsContainer = styled.div`
  margin: 12% 0 0 10%;
  max-width: 55ch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${vp.small(`
    margin-left: 1rem;
    margin-top: 0;
    max-width: 100%;
    `)}

   
`;

export const Text = styled.p`
  font-size: 16px;
  max-width: 35ch;
  margin: 0;
  color: white;
  white-space: pre-line;

  ${vp.small(`
    font-size: 14px;
`)};

  ${vp.medium(`
    max-width: 60ch;
    `)};
`;

export const CompositionContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: grid;
margin: 2rem;

${vp.small(`
    position: relative;
`)};

img {
${vp.medium(`
    width: 50%;
    `)};
}
`
